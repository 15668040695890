.view.set-testimonial {
  .container {
    position: relative;
    padding-top: 135px;

    .form-container {
      margin-top: 45px;
    }

    .ratings-row {
      margin-bottom: 30px;

      > div {
        justify-content: space-between;
        display: flex;
        flex-direction: column;

        label {
          margin-bottom: 7.5px;
          margin-top: 10px;
        }
      }
    }

    .project-completion-qs {
      margin-top: 25px;
      margin-bottom: 10px;

      .top {
        display: flex;
        align-items: center;
      }

      .content {
        max-height: 0;
        overflow: hidden;
        transition: all 0.5s;

        .internal {
          padding: 5px 0;
        }

        &.active {
          max-height: 100px;
        }
      }
    }

    .recommend-container {
      display: flex;
      align-items: center;
      margin-top: 25px;
    }

    .submit-btn {
      margin-top: 20px;
      margin-bottom: 20px;
      padding-bottom: 20px;
    }
  }
}

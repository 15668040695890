.view.view-partners,
.view.view-rfp {
  .main-content {
    position: relative;
    padding-top: 80px;
    height: 100%;

    @media (min-width: $tablet-portrait) {
      padding-top: 100px;
    }

    .container {
      height: 100%;

      .text-center {
        p {
          font-size: 18px;
        }
      }

      @media (max-width: ($tablet-portrait - 1px)) {
        .no-gutters {
          margin: 0 -15px;
        }

        &.active {
          .filters {
            height: 0;
            padding: 0;
            overflow: hidden;
          }

          .col-md-4 {
            margin-left: -100%;
          }

          .pl-back {
            display: flex !important;
            margin: 10px 30px;
          }

          .partners-list {
            display: none;
          }
        }
      }
    }

    .filters {
      position: relative;
      padding: 30px 0;

      @media (min-width: $tablet-portrait) {
        border-bottom: $pl-light-border;
        margin-bottom: 30px;
      }

      .filters-card {
        z-index: 10;
        width: 100%;
      }
    }

    .row {
      height: 100%;

      > div {
      }

      .partners-block {
        //padding-top: 135px;
        height: 100%;
        background: $white;
        border-radius: $card-border-radius / 2;

        .pl-back {
          display: none;
        }

        .partners-list {
          height: calc(100% - 20px);
          z-index: 5;
          position: relative;
          padding: 0;

          @media (min-width: $tablet-portrait) {
            padding: 15px 0;
          }
        }

        .partners-details {
          padding: 0 30px;
          height: calc(100% - 20px);

          @media (min-width: $tablet-portrait) {
            padding: 40px 40px 40px 60px;
          }

          h4 {
            margin: 0 0 15px;
          }

          p {
            word-break: break-word;
          }

          .partner-logo {
            @media (min-width: $tablet-portrait) {
              max-width: 100%;
              margin-right: 30px;
              margin-bottom: 10px;
              display: block;
            }
            display: none;
          }

          .top-bar {
            padding-bottom: 25px;
            margin-bottom: 30px;
            border-bottom: 1px solid $light-grey;
            @media (min-width: $tablet-portrait) {
              display: flex;
            }

            .btn {
              margin-top: 20px;
              @media (min-width: $tablet-portrait) {
                margin: 0;
              }
            }

            .partner-logo {
              max-width: 85px;
            }

            .name-date {
              margin-right: 20px;
            }

            .top-details {
              width: 100%;

              .name-bar {
                display: flex;
                align-items: center;
                margin-bottom: 20px;

                h4 {
                  margin: 0;
                }

                .rating-container {
                  margin: 0 15px;
                }
              }

              .buttons {
                .btn {
                  min-width: unset;
                  width: 100%;
                  margin: 20px 0;
                  height: 40px;
                  line-height: 40px;

                  @media (min-width: $tablet-portrait) {
                    max-width: 160px;
                    margin: 0 10px 0 10px;
                  }
                }
              }
            }
          }

          h2 {
            margin-bottom: 20px;
          }

          p {
            margin-bottom: 20px;
          }
        }

        .partner-services-card {
          @extend .pl-card;
          padding: 35px 35px 25px;
          margin: 0 0 50px;
          border-radius: $card-border-radius / 2;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);

          .detail-title {
            font-size: 14px;
            line-height: 17px;
            font-weight: bold;
            color: $black;
          }

          .detail-value {
            font-size: 14px;
            line-height: 17px;
            font-weight: normal;
            color: $black;
          }

          .no-gutters > div {
            margin-bottom: 10px;
          }

          .sect-2 .detail-value {
            @media (min-width: $tablet-portrait) {
              margin-left: 20px;
            }
          }
        }
      }
    }
  }
}

.view.view-rfp {
  .main-content {
    .row {
      .partners-block {
        padding-top: 0;
      }
    }
  }
}

.inputFile {
  display: none;
}

label {
  font-size: $label-font-size;
  font-family: $label-font-family;
  font-weight: $label-font-weight;
  line-height: $label-line-height;
  color: $label-color;
  margin: $label-margin;
  padding: $label-padding;
}

.form-control {
  height: $input-height;
  font-size: $input-font-size;
  font-family: $input-font-family;
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  margin: $input-margin;
  padding: $input-padding;
  background-color: $input-background-color;
  border-radius: $input-border-radius;
  border-color: $input-border-color;
  border-width: $input-border-width;
  transition: $input-transition;

  &:active, &:focus {
    outline: 0;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.15);
    background-color: $input-background-color;
    color: $input-color;
    border-color: $input-border-color;
  }
}

.form-error {
  color: $error-red;
  font-size: 14px;
  line-height: 17px;
}

.wl-input-group {
  position: relative;
  margin: 10px 0;

  .input-err-message {
    position: absolute;
    color: $error-red;
    line-height: $input-height;
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
    font-family: $input-font-family;
    top: 0;
    right: 30px;
    background: $input-background-color;
  }

  .form-control {
    width: 100%;

    &.error {
      box-shadow: $input-error-bs;
    }
  }
}


textarea {
  resize: none;
  &.form-control {
    padding-top: 7.5px;
    padding-bottom: 7.5px;
    line-height: $input-font-size * 1.5;
  }
}


select.form-control {
  margin: 0;
  width: unset;
  display: initial;
  cursor: pointer;
}

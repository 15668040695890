.view.auth {
  //padding: 0 15px;

  .auth-row {
    display: flex;

    .auth-left-panel {
      height: 100vh;
      padding: 0 35px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;

      @media (min-width: $tablet-portrait) {
        max-width: 47%;
        padding: 0 60px 0 40px;
      }
    }

    .auth-right-panel {
      background: $ac-grey;
      color: $white;
      height: 100vh;
      overflow: auto;
      margin: auto 0;
      padding: 0 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      text-align: center;
      display: none;
      position: fixed;
      right: 0;

      @media (min-width: $tablet-portrait) {
        display: flex;
        max-width: 53%;
      }

      * {
        color: inherit;
      }

      img {
        margin-bottom: 150px;
        border-radius: $input-border-radius;
        max-height: 300px;
      }

      h2 {
        font-weight: 400;
        margin-bottom: 40px;
      }

      p {
        margin: 0 30px;
      }
    }

    .auth-panel-inner {
      max-width: 550px;
      max-height: 100%;
      padding-top: 110px;

      .auth-title-bar {
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
      }

      .btn-primary {
        margin: 35px 0;
      }

      .form-error {
        margin-bottom: -17px;
      }

      .redirect-actions {
        text-align: center;

        * {
          font-size: 14px;
          line-height: 17px;
        }

        .btn {
          margin-left: 5px;
        }
      }
    }
  }

  &.onboarding {
    form {
      margin-bottom: 20px;
    }

    &.details {
      .auth-title-bar {
        .pl-back {
          transition: opacity 0.5s;
        }

        &.step-0 {
          .pl-back {
            opacity: 0;
          }
        }

        &.step-1 {
          .pl-back {
            opacity: 1;
          }
        }
      }

      .auth-panel-inner {
        .details-container {
          overflow: hidden;
          margin: 0 -15px;
          width: calc(100vw - 35px);

          @media(min-width: $tablet-portrait) {
            width: unset;
          }

          .details-row {
            width: 200%;
            display: flex;
            justify-content: space-between;
            overflow: hidden;
            transition: all 0.5s;

            .onboarding-intro {
              height: 0 !important;
            }

            &.step-0 {
              margin-left: 0;

              .onboarding-intro {
                height: initial !important;
              }
            }

            &.step-1 {
              margin-left: -100%;

              form:first-of-type {
                height: 0;
              }
            }

            > * {
              width: 100%;
              margin: 0 15px;
            }
          }
        }
      }
    }

    &.team {
      .invite-team-form {
        margin-bottom: 25px;

        .btn {
          margin: 0;

          &.invite {
            margin: 0 0 25px;

            @media(min-width: $tablet-portrait) {
              margin: 0;
            }
          }
        }

        .row {
          &:last-of-type {
            margin-bottom: 10px;

            @media(min-width: $tablet-portrait) {
              margin-bottom: 100px;
            }
          }
        }
      }
    }

    .auth-left-panel {
      @media (min-width: $tablet-portrait) {
        padding-left: 80px;
      }
    }

    .upload-logo {
      width: 250px;
      height: 250px;
      border-radius: $input-border-radius;
      cursor: pointer;
      background: $input-background-color;
      margin: 10px 0;
      padding: 10px;
      position: relative;
      transition: $input-transition;

      &:empty:after {
        display: block;
        content: url("https://s3.eu-west-2.amazonaws.com/static.partnerlynx.com/icons/pl-logo-icon.svg");
        position: absolute;
        left: 100px;
        top: 100px;
      }

      &.error {
        box-shadow: $input-error-bs;
      }

      img {
        max-width: 100%;
        display: block;
        border-radius: $input-border-radius;
      }
    }

    .onboarding-services-selection {
      max-height: 100%;

      .services-container {
        overflow: hidden;
        margin: 0 -15px;
        width: calc(100vw - 35px);

        @media(min-width: $tablet-portrait) {
          width: unset;
        }
      }
    }
  }
}

.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  position: absolute;
  width: 100vw;
  z-index: 1000;
  border-bottom: $pl-light-border;
  background: $white;

  @media(min-width: $tablet-portrait) {
    padding: 25px 40px;
  }

  &.auth {
    border: none;
    @media(min-width: $tablet-portrait) {
      background: transparent;
    }

    .brand {
      //display: none;

      @media(min-width: $tablet-portrait) {
        display: initial;
      }
    }
  }

  .left {
    display: flex;
    align-items: center;

    .brand {
      font-size: 24px;
      line-height: 30px;
      font-weight: bold;
      margin-right: 10px;

      .logo {
        height: 50px;
      }

      .desktop {
        display: none;

        @media(min-width: $tablet-portrait) {
          display: initial;
        }
      }

      .mobile {
        @media(min-width: $tablet-portrait) {
          display: none;
        }
      }
    }

    .btn-link {
      display: none;
      @media(min-width: $tablet-portrait) {
        display: initial;
      }
    }
  }
}

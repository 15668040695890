.view.profile {

  .profile-content {
    position: relative;
    padding-top: 111px;

    .static-top {
      padding: 30px 0;
      border-bottom: 1px solid rgb(152, 152, 152);

      @media (min-width: $tablet-portrait) {
        margin-bottom: 30px;
      }

      img {
        max-width: 250px;
        display: block;
        margin: 0 auto;

        @media (min-width: $tablet-portrait) {
          max-width: 135px;
          margin: 0 30px 0 0;
        }
      }

      .partner-details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        text-align: center;

        @media (min-width: $tablet-portrait) {
          text-align: left;
        }

        h1 {
          margin: 15px 0;

          @media (min-width: $tablet-portrait) {
            margin: 0;
          }
        }

        .buttons {
          margin-top: 15px;

          .btn-primary {
            margin-bottom: 20px;
            margin-right: 20px;

            @media (min-width: $tablet-portrait) {
              margin-bottom: 0;
            }
          }

          .btn {
            padding: 0 20px;
            min-width: 0;
            width: 100%;

            @media (min-width: $tablet-portrait) {
              width: initial;
            }
          }
        }

        .ratings {
          font-size: 24px;
          line-height: 30px;

          span {
            display: block;

            &:last-child {
              font-size: 14px;
              line-height: 18px;
              margin-top: 2.5px;
            }
          }
        }
      }
    }

    .profile-tabs {
      padding: 30px 0;
      border-bottom: 1px solid rgb(152, 152, 152);
      display: none;

      @media (min-width: $tablet-portrait) {
        display: initial;
      }

      .tabs-row {
        display: flex;
        justify-content: center;
        box-sizing: content-box;

        .tab-title {
          cursor: pointer;
          font-weight: 400;
          width: 80px;
          margin: 0 20px;
          text-align: center;

          &.active,
          &:hover {
            font-weight: 900;
            text-decoration: underline;
          }
        }
      }
    }

    .content {
      padding: 30px 0;
      overflow-x: hidden;

      .container {
        overflow-x: hidden;
        padding: 0;
      }

      .profile-row {
        overflow-x: hidden;
        transition: all 0.5s;

        .btn {
          margin-bottom: 40px;
        }

        @media (min-width: $tablet-portrait) {
          display: flex;
          width: 300%;
        }

        &.step-0 {
          margin-left: 0;
        }

        &.step-1 {
          margin-left: -100%;
        }

        &.step-2 {
          margin-left: -200%;
        }

        > * {
          width: 100%;
          padding: 0 15px 0;
          //max-height: calc(100vh - 530px);
          overflow: auto;

          h3 {
            margin: 10px 0;

            @media (min-width: $tablet-portrait) {
              margin: 0 0 20px;
            }
          }

          .onboarding-service {
            height: unset;
            margin: 25px 0;
          }
        }

        .awards,
        .services,
        .testimonials {
          margin: 15px 0;

          @media (min-width: $tablet-portrait) {
            margin: 0;
          }
        }
      }
    }

    .center-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;

      .pl-card {
        width: 300px;
        height: 300px;
        margin-bottom: 70px;
        position: relative;

        .partner-logo {
          max-width: 100%;
          border-radius: $card-border-radius / 2;
        }

        .edit-pencil {
          position: absolute;
          bottom: 10px;
          right: 10px;
          cursor: pointer;
        }
      }

      .brief-info {
        .partner-name {
          display: flex;
          align-items: center;

          .edit-pencil {
            margin-left: 20px;
            height: 28px;
            width: 26px;
            cursor: pointer;
          }
        }
      }
    }

    .profile-cards {
      .pl-card {
        margin-bottom: 40px;
        font-family: $quicksand-font;

        h6 {
          margin-bottom: 20px;
        }

        .row {
          > div {
            margin-bottom: 10px;

            .detail-title {
              font-size: 14px;
              line-height: 17px;
              font-weight: bold;
              color: $black;
            }

            .detail-value {
              font-size: 14px;
              line-height: 17px;
              font-weight: normal;
              color: $black;
            }
          }
        }
      }
    }

    .award-details {
      padding: 20px 0;
      border-bottom: 1px solid rgb(152, 152, 152);

      .top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        .award-name {
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          text-transform: capitalize;
        }
      }

      p {
        margin: 0;
      }
    }

    .testimonial-details {
      padding: 25px 0;
      cursor: pointer;

      @media (min-width: $tablet-portrait) {
        padding: 25px 20px;
      }

      &.active,
      &:hover {
        background: $input-background-color;
      }

      .spread {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
      }

      .cust-name,
      .proj-name,
      .dates {
        font-weight: bold;
      }

      .rating-container {
        margin: 0;

        .rating-star {
          width: 11px;
          margin: 0;
        }
      }

      p {
        margin-bottom: 0;
      }
    }
  }
}

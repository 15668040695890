html  {
  overflow-x: hidden;
}

body {
  height: 100vh;
  overflow-x: hidden;

  #root {
    height: 100%;
  }
}

* {
  font-family: $quicksand-font;
}

a {
  color: $ac-grey;
  font-weight: 600;

  &:hover {
    color: darken($ac-grey, 20%);
  }
}


.view {
  position: relative;
}

.container {
  @media(min-width: 1650px) {
    max-width: 1500px;
  }
}

//BYTEHOGS
.BHG-pill-container {
  display: flex;
  flex-wrap: wrap;

  .BHG-pill {
    margin: 0 10px 12.5px 0;
    height: 25px;
    line-height: 25px;
    border-radius: 19px;
    padding: 0 15px;
    cursor: pointer;
    user-select: none;
    position: relative;

    .remove-icon {
      position: absolute;
      right: -3px;
      top: -6px;
      width: 15px;
    }
  }
}
